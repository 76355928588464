import React, { Component } from "react";
import PropTypes from "prop-types";

import Select from "react-select";

import { AppSidebarToggler } from "@coreui/react";

import { getLanguages, filterSelect } from "../../util/util";
import { HeaderLogo } from "../../components/Logo/HeaderLogo";
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      width: 0,
      height: 0,
    };
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  changLanguage(language) {
    localStorage.setItem("language", language);
    window.location.reload();
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <HeaderLogo />
            {this.state.width > 1024 && (
              <span
                style={{
                  fontWeight: "bold",
                  color: "#41a7ff",
                  fontSize: "2vh",
                  marginLeft: "-2.5em",
                  marginTop: "0.3em",
                }}
              >
                ID-BLE
              </span>
            )}
          </div>
          <div style={{ marginRight: "20px" }}>
            <Select
              id="selectLanguage"
              name="selectLanguage"
              options={getLanguages()}
              clearable={false}
              hideSelectedOptions
              isSearchable={false}
              value={filterSelect(
                getLanguages(),
                localStorage.getItem("language")
              )}
              onChange={(option) => this.changLanguage(option.value)}
              styles={{
                control: () => ({
                  marginTop: "10px",
                  marginLeft: "10px",
                  background: "transparent",
                }),
                dropdownIndicator: () => ({
                  display: "none",
                }),
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
