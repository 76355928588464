import React, { Component } from 'react';
import PropTypes from 'prop-types';
import util from '../../util/util'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        {process.env.REACT_APP_BRANDING === "viacon" ? 
          <>
            <span><a href="https://viacon.app" rel="noopener noreferrer" target="_blank">Viacon</a> {new Date().getFullYear()} &copy;</span>
            <span className="ml-auto">{process.env.REACT_APP_VERSION_SYSTEM}</span>
          </> :
          <>
            <span><a href="https://datalife.global" rel="noopener noreferrer" target="_blank">DataLife</a> {new Date().getFullYear()} &copy;</span>
            <span className="ml-auto">{util.translate("lgpdText")}</span>
            <span className="ml-auto">{process.env.REACT_APP_VERSION_SYSTEM}</span>
          </>
        }
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
