import React from 'react';

import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/logodatalife_menuapp.svg";
import logoviacon from "../../assets/img/logoviacon_menuapp.svg";
import sygnet from "../../assets/img/brand/datalife-idble-logo.svg";
import sygnetviacon from "../../assets/img/brand/datalife-idble-logo.svg";

const logoFull = process.env.REACT_APP_BRANDING === "viacon" ? logoviacon : logo;
const logoMinimized = process.env.REACT_APP_BRANDING === "viacon" ? sygnetviacon : sygnet;
const logoAlt = process.env.REACT_APP_BRANDING === "viacon" ? "Logo viacon" : "Logo Data Life";

export const HeaderLogo = () => {
  return (
    <AppNavbarBrand
      style={{
        backgroundColor: "#2868F1",
        marginLeft: window.innerWidth > 1024 ? "-1.5em" : "1.5em",
      }}
      full={{
        src: logoFull,
        alt: logoAlt,
        width: "80%",
        height: "80%",
      }}
      minimized={{
        src: logoMinimized,
        alt: logoAlt,
        width: 30,
        height: 30,
      }}
    />
  );
};
