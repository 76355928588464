const encryptUtil = require("./encryptUtil");
const passKeyToken = "52f6b6a1-d725-439e-80c3-69a76ddd";
const maxTimeSession = 300000;

/**
 * Criação do token com o tempo do usuario na sessao
 */
const createSessionToken = function () {
  const baseToken = `tokenTime:${new Date().getTime()}`;
  const token = encryptUtil.encrypt(baseToken, passKeyToken);
  sessionStorage.setItem("session", token);
  verifyUpdateJwtToken();
};

/**
 * Verifica se é necessário atualizar o token JWT utilizado na autenticação do backend
 */
function verifyUpdateJwtToken() {
  let timeTokenValidate = sessionStorage.getItem("ttv");
  if (timeTokenValidate) {
    timeTokenValidate = +encryptUtil.decrypt(timeTokenValidate);
    const time = new Date() - new Date(timeTokenValidate);
    if (time > maxTimeSession * 3) {
      sessionStorage.setItem(
        "ttv",
        encryptUtil.encrypt(new Date().getTime().toString())
      );
      updateJwtToken();
    }
  }
}

/**
 * Verifica se o token da sessão do usuário ainda é valido
 */
const isSessionTokenValid = function () {
  // const sessionToken = sessionStorage.getItem('session');
  // if (sessionToken) {
  //   let token = encryptUtil.decrypt(sessionToken, passKeyToken);
  //   if (token && token.length > 2) {
  //     token = token.split(':');
  //     if (token.length === 2 && token[0] === 'tokenTime') {
  //       const actualTime = new Date().getTime();
  //       if (actualTime - (+token[1]) < maxTimeSession) {
  //         return true;
  //       }
  //     }
  //   }
  // }
  // sessionStorage.clear();
  // return false;
  return true;
};

/**
 * Atualiza a validade do token JWT no servidor e atualiza o token armazenado no session storage
 */
const updateJwtToken = function () {
  let configs = encryptUtil.decrypt(sessionStorage.getItem("userConfig"));
  if (configs) {
    configs = JSON.parse(configs);
    const id = configs.id;
    const url = process.env.REACT_APP_URI + "/security/update?id=" + id;
    fetch(url, getRequestOptions())
      .then((data) => data.json())
      .then((res) => {
        const token = res.token;
        if (token) {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem(
            "ttv",
            encryptUtil.encrypt(new Date().getTime().toString())
          );
        }
      });
  }
};

/**
 * Retorna as opções da requisição para as comunicações com o backend
 *
 * @param {*} method - GET, POST, PUT, DELETE
 * @param {*} body - objeto com as informações para o corpo da requisiçao
 */
const getRequestOptions = (method = "GET", body = null) => {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    userid: getIdUser(),
    locale: localStorage.getItem("language"),
    "Content-Type": "application/json",
  };

  const requestOptions = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  };
  return requestOptions;
};

/**
 * Retorna o id do usuario logado para ser anexado no header da requisicao
 */
const getIdUser = () => {
  let userConfigs = getUserConfigs();
  if (userConfigs) {
    return userConfigs.id;
  }
  return null;
};

/**
 * Retorna as informações dos usuario logado armazenada no session storage
 */
const getUserConfigs = function () {
  let userConfigs = sessionStorage.getItem("userConfig");
  if (userConfigs) {
    userConfigs = JSON.parse(encryptUtil.decrypt(userConfigs));
    return userConfigs;
  }
  return null;
};

/**
 * Retorna o perfil do usuario logado
 */
const getUserProfile = function () {
  const userConfig = getUserConfigs();
  if (userConfig) {
    return userConfig.profile;
  }
  return null;
};

const showPrivacy = () => {
  const userConfig = getUserConfigs();
  if (!userConfig) return false;
  if (!userConfig.showPrivacy) return false;
  if (!userConfig.privacy) return true;
  return false;
};

module.exports = {
  createSessionToken,
  isSessionTokenValid,
  updateJwtToken,
  getUserConfigs,
  getUserProfile,
  getRequestOptions,
  getIdUser,
  showPrivacy,
};
