import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import CacheBuster from "./CacheBuster";
import "./App.scss";
import "leaflet/dist/leaflet.css";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import { Login, Reset, Page404, Page500, Register } from "./views/Pages";
import WorkerPassword from "./views/Pessoas/password/workerPassword";
import UserPassword from "./views/Users/userPassword";
import intl from "react-intl-universal";
import TagManager from "react-gtm-module";
import Favicon from "react-favicon";

const locales = {
  "pt-BR": require("./locales/pt-BR.json"),
  "en-US": require("./locales/en-US.json"),
  "it-IT": require("./locales/it-IT.json"),
  "es-ES": require("./locales/es-ES.json"),
};

// import { renderRoutes } from 'react-router-config';
if (window.location.toString().includes("datalife.global")) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GMT || "" });
}

const iconURL = process.env.REACT_APP_BRANDING === 'viacon' ? require('./assets/favicon-viacon.png') : require('./assets/favicon.png');

class App extends Component {
  constructor() {
    super();
    const navigatorLocale = locales[navigator.language]
      ? navigator.language
      : "pt-BR";
    const locale = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : navigatorLocale;
    localStorage.setItem("language", locale);

    const currentLocale = locale;
    intl.init({
      currentLocale,
      locales,
    });
  }
  render() {
    return (<>
      <Favicon url={iconURL} keepIconLink={() => true} />
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <HashRouter>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  component={Login}
                />
                <Route
                  exact
                  path="/workerPassword"
                  name="Password"
                  component={WorkerPassword}
                />
                <Route
                  exact
                  path="/userPassword"
                  name="User Password"
                  component={UserPassword}
                />
                <Route
                  exact
                  path="/reset_pwd"
                  name="Reset Password Page"
                  component={Reset}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  component={Register}
                />
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Route path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </HashRouter>
          );
        }}
      </CacheBuster>
      </>
    );
  }
}

export default App;
