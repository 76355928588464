import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import util from "../../../util/util";
import { MainLogo } from "../../../components/Logo/MainLogo";

const optionsToast = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

class Reset extends Component {
  constructor(props) {
    super(props);

    this.reset = this.reset.bind(this);

    this.state = {
      user: null,
      passwd: "",
      confirm_passwd: "",
      encrypt_pwd: "",
      userName: "",
      showButton: true,
      showMsgDefaultPass: "",
      token: "",
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    this.setState({ encrypt_pwd: params.get("param") });
    this.validateToken(params.get("param"));
    this.msgToolTip();
  }

  /**
   * Pega msg para informar o padrão da senha
   * Função localizada em util.showMsgDefaul()
   */
  async msgToolTip() {
    const msgsHtml = util.showMsgDefaul();
    const msg = msgsHtml.map((m) => <p>{m}</p>);
    await this.setState({ showMsgDefaultPass: msg });
  }

  async validateToken(token) {
    const url = process.env.REACT_APP_URI + "/validations/verifyTokenUser";
    const resp = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });
    if (resp.status === 200) {
      const res = await resp.json();
      this.setState({
        token: res.token,
        name: res.name,
      });
      const language = localStorage.getItem("language");
      if (language && language !== res.locale) {
        localStorage.setItem("language", res.locale);
        window.location.reload();
      }
    } else {
      this.setState({ showButton: false });
      toast.error(
        <div>
          {" "}
          <h4>
            {resp.msg}
            <br />{" "}
          </h4>
          {util.translate("requestNewPasswordChange")}
        </div>,
        optionsToast
      );
    }
  }

  async reset() {
    if (
      this.state.passwd !== this.state.confirm_passwd ||
      this.state.passwd === "" ||
      this.state.confirm_passwd === ""
    ) {
      toast.error(
        <div>
          {" "}
          <h4>
            {"SENHAS E CONFIRMAÇÂO NÃO COINCIDEM"}
            <br />{" "}
          </h4>
        </div>,
        optionsToast
      );
      return;
    }
    if (
      this.state.passwd.length < 12 ||
      this.state.confirm_passwd.length < 12
    ) {
      toast.error(
        <div>
          {" "}
          <h4>
            {"Senha deve ter 12 ou mais caracteres"}
            <br />{" "}
          </h4>
        </div>,
        optionsToast
      );
      return;
    }
    const formData = {
      password: this.state.passwd,
    };
    const url = process.env.REACT_APP_URI + "/validations/resetPassword";

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(formData),
    });

    const res = await resp.json();
    if (resp.status === 200) {
      toast.success(
        <div>
          {" "}
          <h4>
            {util.translate("resetPasswordSuccessfully")}
            <br />{" "}
          </h4>
        </div>,
        { onClose: this.props.history.push("/login") },
        optionsToast
      );
    } else {
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("resetPasswordFailure")}
            <br />{" "}
          </h4>
          {util.translate(res.token)}
        </div>,
        optionsToast
      );
    }
  }

  render() {
    return (
      <div
        className="app flex-row align-items-center"
        style={{ backgroundColor: "#080864" }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <Container>
          <Row
            className="justify-content-center"
            style={{ marginTop: "-10em" }}
          >
            <MainLogo width="40%" height="40%" marginBottom="3em" />
          </Row>
          <Row className="justify-content-center" style={{ marginTop: "0em" }}>
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>
                        {util.translate("resetPassword")} - {this.state.name}
                      </h1>
                      <p className="text-muted"></p>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            {/* <i className="icon-lock"></i> */}
                            <div>
                              <i
                                id="explainPassword"
                                className="fa fa-info"
                              ></i>
                              <UncontrolledTooltip
                                style={{ backgroundColor: "#080864" }}
                                placement="top"
                                target="explainPassword"
                              >
                                <p
                                  style={{
                                    color: "rgb(255, 255, 255)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.state.showMsgDefaultPass}
                                </p>
                              </UncontrolledTooltip>
                            </div>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          placeholder={util.translate("password")}
                          autoComplete="current-password"
                          maxLength="100"
                          minLength="12"
                          onFocus={() => this.setState({ passwd: "" })}
                          value={this.state.passwd}
                          onChange={(senha) =>
                            this.setState({ passwd: senha.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder={util.translate("confirmarPassword")}
                          autoComplete="current-password"
                          maxLength="100"
                          minLength="12"
                          onFocus={() => this.setState({ confirm_passwd: "" })}
                          value={this.state.confirm_passwd}
                          onChange={(senha) =>
                            this.setState({
                              confirm_passwd: senha.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              console.log(event.key);
                              this.reset(this.state.passwd);
                            }
                          }}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          {this.state.showButton && (
                            <Button
                              color="primary"
                              className="px-4"
                              onClick={() => this.reset(this.state.passwd)}
                            >
                              {util.translate("submit")}
                            </Button>
                          )}
                        </Col>
                        <Col xs="6" className="text-right"></Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Reset;
