import React, { useState, useEffect} from 'react';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

import util from '../../../util/util';
import {getRequestOptions} from '../../../util/session';

import "./styles.css";

export default function ModalListNotification(props) {

  const [ data, setData] = useState([]);
  const [ total, setTotal] = useState(0);

  useEffect(() => {
    if (props.openModal) {
      populateNotification();
    }
  }, [props.openModal])

  const getHeaderStyle = (textAlign = 'center') => {
    return { backgroundColor: 'rgb(241, 242, 247)', textAlign, fontSize: '1.3em'};
  }
  const onTableChange = async (type, { page, sizePerPage }) => {
    populateNotification(page)
  }

  /** Populando tabela de modal
   *
   */
  const populateNotification = async (page = 0) => {
    const skip = page > 1 ? (page - 1) * 5 : 0;

    const url = `${process.env.REACT_APP_URI}/notification/findUser?&skip=${skip}&limit=5`;
    const resp = await fetch(url, getRequestOptions());
    const res = await resp.json();
    setData(res.rows ? res.rows : []);
    setTotal(res.count ? res.count : 0);
  }

  const actionsLink = ( cell,row) => {
      return (
        <div>
          <Button
            color="link"
            title="Visualizar"
            onClick={() =>
              props.controlNotificationModal(row)}
            >
            <i className={'fa fa-search'}/></Button>
        </div>
      )
  }

  const titleFormatter = (cell, row) => {
    return <div className={!row.dateRead ? 'fontBold' : 'fontRead'}>{cell}</div>
  }

  const dateFormatter = (cell, row) => {
        if (cell) {
          const d = new Date(cell);
          return (
            <div>
              <h3 className={!row.dateRead ? 'fontBold' : 'fontRead'} >{moment(d).format('DD/MM/YYYY')}</h3>
            </div>
          );
        }
  }

  const dateFormatterRead = (cell, row) => {
    if (row.dateRead) {
      return (
        <div>
          <h3 className={!row.dateRead ? 'fontBold' : 'fontRead'}>{moment(row.dateRead).format('DD/MM/YYYY')}</h3>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  const typeFormatter = (cell, row) => {
    return <div className={!row.dateRead ? 'fontBold' : 'fontRead'} >
    {util.getLabelTypeNotification(cell)}
    </div>
   }
  const columns = [{
      dataField: 'title',
      text: 'Título',
      formatter: titleFormatter,
      headerStyle: {getHeaderStyle, width:"100px"},

    }, {
      dataField: 'date',
      text: 'Data da notificação',
      formatter: dateFormatter,
      headerStyle: {getHeaderStyle, width: "100px", textAlign: 'center'},
      align: 'center',
    }, {
      dataField: 'dateRead',
      text: 'Data da Leitura',
      formatter: dateFormatterRead,
      headerStyle: {getHeaderStyle, width: "100px", textAlign: 'center'},
      align: 'center',

    }, {
      dataField: 'type',
      text: 'Tipo',
      headerAlign: 'center',
      align: 'center',
      headerStyle: {getHeaderStyle, width: "100px", textAlign: 'center'},
      formatter: typeFormatter,

    }, {
      dataField: 'userId',
      text: 'Ações',
      headerAlign: 'center',
      align: 'center',
      formatter: actionsLink,
      headerStyle: {getHeaderStyle, width: "40px"},

    }
  ]

  const customTotal = (from, to, size) => (
    <span className="tableTotal">
      Mostrando {from} à {to} de {size} Notificações
    </span>
  )

  const options_pagination = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [5],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'Primeira',
    prePageText: 'Voltar',
    nextPageText: 'Próxima',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira Página',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Pŕoxima Página',
    lastPageTitle: 'Última Página',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: total,
  };

  return (
    <>
      <Modal isOpen={props.openModal} size="xl" toggle={props.closeModal}>

          <div className="headerModal">
              <strong> Notificações</strong>
              <Button
                id="btnModalClose"
                name="btnModalClose"
                color='link'
                onClick={props.closeModal}
                >Fechar <i className="fa fa-close"></i>
              </Button>
          </div>
          <ModalBody style={{textAlign: 'center'}}>
            <PaginationProvider pagination={paginationFactory(options_pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <div style={{ marginTop: '3em' }}>
                    <BootstrapTable
                      remote
                      keyField='id'
                      data={data}
                      columns={columns}
                      hover condensed
                      bordered={false}
                      noDataIndication="Sem dados para exibir"
                      {...paginationTableProps}
                      onTableChange={onTableChange}
                    />
                    <Row>
                      <Col>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col>
                        <Row style={{ justifyContent: 'flex-end', marginLeft: '1px', marginRight: '3px', marginTop: '1em' }}>
                          <PaginationListStandalone {...paginationProps} />
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  )}
              </PaginationProvider>
          </ModalBody>
      </Modal>
    </>
  );
}
