import React from "react";
import Loadable from "react-loadable";
import util from "./util/util";

import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {util.translate("loading")}
      <img
        alt="loading"
        src="assets/img/loading.gif"
        style={{ width: "75px", height: "75px" }}
      ></img>
    </div>
  );
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const User = Loadable({
  loader: () => import("./views/Users/User"),
  loading: Loading,
});

const UserRegister = Loadable({
  loader: () => import("./views/Users/UserRegister"),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import("./views/Pessoas/access_control/changePassword"),
  loading: Loading,
});

const ChangeDatas = Loadable({
  loader: () => import("./views/Users/ChangeDatas"),
  loading: Loading,
});

const notificationRelatorio = Loadable({
  loader: () => import("./views/Notification/relatorio/relatorio"),
  loading: Loading,
});

const notificationCadastro = Loadable({
  loader: () => import("./views/Notification/cadastro/cadastro"),
  loading: Loading,
});

const workers_report = Loadable({
  loader: () => import("./views/Workers/report/Report"),
  loading: Loading,
});

const workers_cadastro = Loadable({
  loader: () => import("./views/Pessoas/cadastro"),
  loading: Loading,
});

const workers_detalhes = Loadable({
  loader: () => import("./views/Pessoas/informacoes"),
  loading: Loading,
});

const clientesRelatorio = Loadable({
  loader: () => import("./views/ClientHolding/relatorio"),
  loading: Loading,
});

const clientesCadastro = Loadable({
  loader: () => import("./views/ClientHolding/cadastro"),
  loading: Loading,
});

const companies_relatorio = Loadable({
  loader: () => import("./views/Empresas/relatorio"),
  loading: Loading,
});

const companies_cadastro = Loadable({
  loader: () => import("./views/Empresas/cadastro"),
  loading: Loading,
});

const contracteds_relatorio = Loadable({
  loader: () => import("./views/Contracteds/reports/ContractedReport"),
  loading: Loading,
});

const contracteds_registro = Loadable({
  loader: () => import("./views/Contracteds/registers/ContractedRegister"),
  loading: Loading,
});

const contracteds_details = Loadable({
  loader: () => import("./views/Contracteds/details/MainPage"),
  loading: Loading,
});

const supervisions_relatorio = Loadable({
  loader: () => import("./views/Supervisoes/relatorio"),
  loading: Loading,
});

const supervisions_detalhes = Loadable({
  loader: () => import("./views/Supervisoes/informacoes"),
  loading: Loading,
});

const plants_relatorio = Loadable({
  loader: () => import("./views/Empreendimentos/relatorio"),
  loading: Loading,
});

const plants_cadastro = Loadable({
  loader: () => import("./views/Empreendimentos/cadastro"),
  loading: Loading,
});

const documents_relatorio = Loadable({
  loader: () => import("./views/Documentos/relatorio"),
  loading: Loading,
});

const documentsActives = Loadable({
  loader: () => import("./views/DocumentosAtivos/relatorio"),
  loading: Loading,
});

const accessReports = Loadable({
  loader: () => import("./views/Reports/accessReport"),
  loading: Loading,
});

const relatorio = Loadable({
  loader: () => import("./views/Relatorio"),
  loading: Loading,
});

const backup_db = Loadable({
  loader: () => import("./views/Pages/DB_bkp"),
  loading: Loading,
});

const jobs = Loadable({
  loader: () => import("./views/Jobs/relatorio"),
  loading: Loading,
});

const jobs_cadastro = Loadable({
  loader: () => import("./views/Jobs/cadastro"),
  loading: Loading,
});

const actives = Loadable({
  loader: () => import("./views/TipoAtivo/relatorio"),
  loading: Loading,
});

const activesCadastro = Loadable({
  loader: () => import("./views/TipoAtivo/cadastro"),
  loading: Loading,
});

const active_report = Loadable({
  loader: () => import("./views/Workers/report/Report"),
  loading: Loading,
});

const ativosCadastro = Loadable({
  loader: () => import("./views/Ativos/cadastro"),
  loading: Loading,
});

const ativosDetalhes = Loadable({
  loader: () => import("./views/Ativos/informacoes"),
  loading: Loading,
});

const unidade_operacional = Loadable({
  loader: () => import("./views/UnidadeOperacional/relatorio"),
  loading: Loading,
});

const unidade_operacional_cadastro = Loadable({
  loader: () => import("./views/UnidadeOperacional/cadastro"),
  loading: Loading,
});
const qrcodeDocuments = Loadable({
  loader: () => import("./views/QRCode/MainPage"),
  loading: Loading,
});

const smartBadge = Loadable({
  loader: () => import("./views/SmartBadges/Map"),
  loading: Loading,
});

const tracking = Loadable({
  loader: () => import("./views/SmartBadges/Tracking"),
  loading: Loading,
});

const duplicate = Loadable({
  loader: () => import("./views/Duplicate"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/dashboard",
    name: "Home",
    component: DefaultLayout,
    exact: true,
  },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/usuarios", exact: true, name: "Users", component: User },
  {
    path: "/usuarios/cadastro",
    exact: true,
    name: "User Details",
    component: UserRegister,
  },
  {
    path: "/usuarios/alterarSenha",
    exact: true,
    name: "Change password",
    component: ChangePassword,
  },
  {
    path: "/usuarios/alterarDados",
    exact: true,
    name: "Change datas",
    component: ChangeDatas,
  },
  {
    path: "/notification/relatorio",
    exact: true,
    name: "Relatorio",
    component: notificationRelatorio,
  },
  {
    path: "/notification/cadastro",
    exact: true,
    name: "Cadastro",
    component: notificationCadastro,
  },

  {
    path: "/Workers/report",
    exact: true,
    name: "Report",

    component: workers_report,
  },
  {
    path: "/pessoas/cadastro",
    exact: true,
    name: "Cadastro",
    component: workers_cadastro,
  },
  {
    path: "/pessoas/informacoes",
    exact: true,
    name: "Informações",
    component: workers_detalhes,
  },

  {
    path: "/clientes",
    name: "Holding",
    component: clientesRelatorio,
    exact: true,
  },
  {
    path: "/clientes/relatorio",
    exact: true,
    name: "Relatório",
    component: clientesRelatorio,
  },
  {
    path: "/clientes/cadastro",
    exact: true,
    name: "Cadastro",
    component: clientesCadastro,
  },

  {
    path: "/empresas",
    name: "Empresas",
    component: companies_relatorio,
    exact: true,
  },
  {
    path: "/empresas/relatorio",
    exact: true,
    name: "Relatório",
    component: companies_relatorio,
  },
  {
    path: "/empresas/cadastro",
    exact: true,
    name: "Cadastro",
    component: companies_cadastro,
  },
  {
    path: "/contracteds",
    name: "Contratadas",
    component: contracteds_relatorio,
    exact: true,
  },
  {
    path: "/contracteds/relatorio",
    exact: true,
    name: "Relatório",
    component: contracteds_relatorio,
  },
  {
    path: "/contracteds/cadastro",
    exact: true,
    name: "Relatório",
    component: contracteds_registro,
  },
  {
    path: "/contracteds/details",
    exact: true,
    name: "Detalhes",
    component: contracteds_details,
  },

  {
    path: "/supervisoes",
    name: "Supervisões",
    component: supervisions_relatorio,
    exact: true,
  },
  {
    path: "/supervisoes/informacoes",
    exact: true,
    name: "Informações",
    component: supervisions_detalhes,
  },
  {
    path: "/empreendimentos",
    name: "Empreendimentos",
    component: plants_relatorio,
    exact: true,
  },
  {
    path: "/empreendimentos/relatorio",
    name: "Relatório",
    component: plants_relatorio,
  },
  {
    path: "/empreendimentos/cadastro",
    name: "Cadastro",
    component: plants_cadastro,
  },
  { path: "/documentos", name: "Documentos", component: documents_relatorio },
  {
    path: "/documentosAtivos",
    name: "Documentos Ativos",
    component: documentsActives,
  },
  { path: "/relatorio", name: "Relatório", component: relatorio },
  { path: "/qrCode", name: "QrCode", component: qrcodeDocuments },
  { path: "/bkp_db", name: "Backup_DB", component: backup_db },
  { path: "/jobs", exact: true, name: "Relatório", component: jobs },
  {
    path: "/jobs/cadastro",
    exact: true,
    name: "Cadastro",
    component: jobs_cadastro,
  },

  {
    path: "/tipoAtivo",
    exact: true,
    name: "Tipo de Ativo",
    component: actives,
  },
  {
    path: "/tipoAtivo/cadastro",
    exact: true,
    name: "Cadastro",
    component: activesCadastro,
  },

  {
    path: "/accessReport",
    exact: true,
    name: "Relatorios",
    component: accessReports,
  },
  {
    path: "/Actives/report",
    exact: true,
    name: "Report",
    component: active_report,
  },
  {
    path: "/ativos/cadastro",
    exact: true,
    name: "Cadastro",
    component: ativosCadastro,
  },
  {
    path: "/ativos/informacoes",
    exact: true,
    name: "Informações",
    component: ativosDetalhes,
  },

  {
    path: "/uo",
    exact: true,
    name: "Relatório",
    component: unidade_operacional,
  },
  {
    path: "/uo/cadastro",
    exact: true,
    name: "Cadastro",
    component: unidade_operacional_cadastro,
  },
  {
    path: "/SmartBadges/Map",
    exact: true,
    name: "Map",
    component: smartBadge,
  },
  {
    path: "/SmartBadges/Tracking",
    exact: true,
    name: "Tracking",
    component: tracking,
  },
  {
    path: "/duplicate",
    exact: true,
    name: "Duplicate",
    component: duplicate,
  },
];

export default routes;
