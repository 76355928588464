import React, { useEffect } from 'react';
import { Modal, ModalBody, Form , ModalFooter, Button} from 'reactstrap';
import moment from 'moment';

import {getRequestOptions, getUserConfigs} from '../../../util/session';

import './styles.css';

export default function ModalReadNotification(props) {

 useEffect(() => {
  if (props.openModal) {
    if (!props.row.dateRead) {
      readDateNotification( new Date());
      props.updateBadge();
    }
  }
}, [props.openModal])


  const readDateNotification = async (date) => {

    const dateNotification = {
      notificationId: props.row.id,
      userId: getUserConfigs().id,
      date
    }
    let url = process.env.REACT_APP_URI + '/notification/readUser';
    const method = 'PUT';
    await fetch(url, getRequestOptions(method,dateNotification));
  }

  return (
    <Modal isOpen={props.openModal} size="lg" toggle={props.closeModal}>
      <Form>
        <div className="headerModal">
            <span><strong>Notificações</strong></span>
            <Button
                id="btnModalClose"
                name="btnModalClose"
                color='link'
                onClick={props.closeModal}
                >Fechar <i className="fa fa-close"></i>
              </Button>
        </div>
        <ModalBody >
          <div style={{ borderStyle: 'hidden' ,border: '5px', padding: '5px', fontSize: '20px' }}>
            <strong>{props.row ? props.row.title : ''}</strong><br/>
            {props.row ? moment(props.row.date).format('DD/MM/YYYY') : ''}<br/>
          </div>
          <div style={{ borderStyle: 'hidden', border: '15px', padding: '5px' }}>
            <div dangerouslySetInnerHTML={{__html:props.row ? props.row.text : ''}}></div>
          </div>
        </ModalBody>
        <ModalFooter style={{justifyContent: 'center'}}>
          <Button
            id="btnUnread"
            name="btnUnread"
            color='primary'
            onClick={async () => {
              await readDateNotification( null );
              await props.controlNotificationModal();
            }}
            >Marcar como não lida
          </Button>
          <Button
            id="btnBack"
            name='btnBack'
            color='primary'
            onClick={() => {
              props.controlNotificationModal();
              }}
            >Voltar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
