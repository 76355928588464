import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppFooter, AppHeader } from "@coreui/react";

import DefaultFooter from "../../containers/DefaultLayout/DefaultFooter";
import HeaderNoOptions from "../../containers/DefaultLayout/HeaderNoOptions";
import util from "../../util/util";

const optionsToast = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

class UserPassword extends Component {
  constructor() {
    super();

    this.save = this.save.bind(this);

    this.state = {
      name: "",
      token: "",
      showForm: false,
      showError: false,
      textError: "",
      password: "",
      confirmPassword: "",
      showAlert: true,
      textAlert: "",
      colorAlert: "",
      showRedirect: false,
      showMsgDefaultPass: "",
    };
  }

  componentDidMount() {
    const query = this.props.location.search.slice(1);
    let partes = query.split("&");
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split("=");
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });
    this.verfiyTokenValid(data);
    this.setState({ token: data.id });
    this.msgToolTip();
  }

  /**
   * Pega msg para informar o padrão da senha
   * Função localizada em util.showMsgDefaul()
   */
  async msgToolTip() {
    const msgsHtml = util.showMsgDefaul();
    const msg = msgsHtml.map((m) => <p>{m}</p>);
    await this.setState({ showMsgDefaultPass: msg });
  }

  async verfiyTokenValid(data) {
    if (data && data.id) {
      const formData = {
        token: data.id,
      };

      const url = process.env.REACT_APP_URI + "/validations/verifyTokenUser";
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        body: JSON.stringify(formData),
      });
      const user = await resp.json();
      if (resp.status === 200) {
        if (user) {
          this.setState({
            showForm: true,
            name: user.name,
            token: user.token,
          });
          if (!localStorage.getItem("verifyLanguage", true)) {
            const language = localStorage.getItem("language");
            localStorage.setItem("verifyLanguage", true);
            if (language && language !== user.locale) {
              localStorage.setItem("language", user.locale);
              window.location.reload();
            }
          }
        } else {
          this.setState({
            showError: true,
            showForm: false,
            textError: `Não foi possível validar os dados! <br>${user.msg}<br>Solicite ao administrador um novo link para o cadastro da senha.`,
          });
        }
      } else {
        this.setState({
          showError: true,
          showForm: false,
          textError: `Não foi possível validar os dados! <br>${user.msg}<br>Solicite ao administrador um novo link para o cadastro da senha.`,
        });
      }
    } else {
      this.setState({
        showError: true,
        showForm: false,
        textError: util.translate("invalidData!")(
          util.translate("AskTheAdministratorForaNewLinkRegisterPassword")
        ),
      });
    }
  }

  /**
   * Verifica se senha porrui
   * Tamanho: 8
   * Ao menos 3 dos:
   * Letra: Maiuscula, Minuscula
   * Numero: Ao menos um
   * Caracter: Ao menos um
   * @param {*} password
   */
  async validateForce(password) {
    let letraMaiuscula = /^(?=.*?[A-Z])/;
    let letraMinuscula = /^(?=.*?[a-z])/;
    let numero = /^(?=.*?[0-9])/;
    let caracter = /^(?=.*?[#?!@$%^&*-])/;
    let tamanho = password.length;
    let msgs = [];

    if (tamanho < 8) {
      toast.error(
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
            {util.translate("invalidData")}
          </span>
          <br />{" "}
          <div style={{ fontSize: "1.2em" }}>
            {util.translate("thePasswordMustBeTwelveOrMoreCharactersLong")}
          </div>
        </div>,
        optionsToast
      );
      return;
    }

    if (!letraMaiuscula.test(password)) {
      msgs.push(util.translate("enterThePasswordWithCapitalLetter."));
    }
    if (!letraMinuscula.test(password)) {
      msgs.push(util.translate("enterThePasswordWithLowercaseLetter"));
    }
    if (!numero.test(password)) {
      msgs.push(util.translate("enterThePasswordWithNumber"));
    }
    if (!caracter.test(password)) {
      msgs.push(util.translate("enterOasswordWithSpecialCharacter"));
    }

    if (msgs.length > 1) {
      const msgsHtml = msgs.map((m) => <p>{m}</p>);
      toast.error(
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
            {util.translate("invalidData")}
          </span>
          <br /> <div style={{ fontSize: "1.2em" }}>{msgsHtml}</div>
        </div>,
        optionsToast
      );
    } else {
      await this.save();
    }
  }

  async save() {
    if (
      this.state.password === "" ||
      this.state.confirmPassword === "" ||
      this.state.password !== this.state.confirmPassword
    ) {
      toast.error(
        <div>
          {" "}
          <h4>
            {util.translate("invalidData")}
            <br />{" "}
          </h4>
        </div>,
        optionsToast
      );
    } else {
      const formData = {
        password: this.state.password,
      };
      const url = process.env.REACT_APP_URI + "/validations/savePasswordUser";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 200) {
        this.setState({ showRedirect: true });
        setTimeout(() => {
          this.props.history.push({ pathname: "/" });
        }, 5000);
        toast.info(
          <div>
            {" "}
            <h4>
              {util.translate("passwordRegisteSucceefully!")}
              <br />{" "}
            </h4>
          </div>,
          optionsToast
        );
      } else {
        toast.error(
          <div>
            {" "}
            <h4>
              {util.translate("itWasNotPossibleToRegisterPassword")}
              <br />{" "}
            </h4>
          </div>,
          optionsToast
        );
      }
    }
  }

  goLogin() {
    this.props.history.push({ pathname: "/", query: { back: true } });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      {util.translate("loading")}
    </div>
  );

  render() {
    return (
      <div className="app">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <AppHeader fixed style={{ backgroundColor: "#2868F1" }}>
          <HeaderNoOptions />
        </AppHeader>
        <div className="app-body">
          <main className="main" style={{ backgroundColor: "white" }}>
            <Container fluid style={{ marginTop: "2em" }}>
              {this.state.showError && (
                <Card>
                  <CardBody
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.textError }}
                    ></div>
                  </CardBody>
                </Card>
              )}
              {this.state.showForm && (
                <Card>
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#2868F1",
                      marginLeft: "20px",
                    }}
                  >{`${util.translate("userPasswrodRegistration")} - ${
                    this.state.name
                  }`}</span>
                  <CardBody>
                    <h5 style={{ fontWeight: "bold", color: "#0e457b" }}>
                      {util.translate("passwordRegistration")}
                    </h5>
                    <hr />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label
                              htmlFor="password"
                              style={{ fontWeight: "bold" }}
                            >
                              {util.translate("password")}
                            </Label>
                            <InputGroup className="mb-4">
                              <Input
                                valid={this.state.password !== ""}
                                invalid={this.state.password === ""}
                                type="password"
                                id="password"
                                name="password"
                                placeholder={util.translate("typePassword")}
                                onChange={(option) =>
                                  this.setState({
                                    password: option.target.value,
                                  })
                                }
                              />

                              <InputGroupAddon addonType="prepend">
                                <InputGroupText
                                  style={{
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                >
                                  <i
                                    id="explainPassword"
                                    className="fa fa-info"
                                  ></i>
                                  <UncontrolledTooltip
                                    style={{
                                      backgroundColor: "rgb(255, 255, 255)",
                                      fontWeight: "bold",
                                    }}
                                    placement="top"
                                    target="explainPassword"
                                  >
                                    <p style={{ color: "rgb(0, 0, 158)" }}>
                                      {this.state.showMsgDefaultPass}
                                    </p>
                                  </UncontrolledTooltip>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            <FormFeedback className="help-block">
                              {util.translate(
                                "thePasswordMustBeTwelveOrMoreCharactersLong"
                              )}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label
                              htmlFor="confirmPassword"
                              style={{ fontWeight: "bold" }}
                            >
                              {util.translate("confirmPassword")}
                            </Label>
                            <Input
                              valid={
                                this.state.password ===
                                this.state.confirmPassword
                              }
                              invalid={
                                this.state.password !==
                                this.state.confirmPassword
                              }
                              type="password"
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder={util.translate(
                                "confirmYourPassword"
                              )}
                              onChange={(option) => {
                                this.setState({
                                  confirmPassword: option.target.value,
                                });
                              }}
                            />
                            <FormFeedback className="help-block"></FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{ height: "50px", justifyContent: "center" }}>
                        <Col sm={{ size: "auto", offset: 0 }}>
                          {!this.state.showRedirect && (
                            <Button
                              name="concCadbtn"
                              id="concCadBtn"
                              size="xl"
                              color="primary"
                              onClick={() => {
                                this.validateForce(this.state.password);
                              }}
                              style={{ marginRight: "50px" }}
                            >
                              {util.translate("completeRegistration")}
                            </Button>
                          )}
                          {this.state.showRedirect && (
                            <Button
                              id="reLoginBtn"
                              name="reLoginBtn"
                              outline
                              size="xl"
                              color="success"
                              onClick={() => this.goLogin()}
                            >
                              {util.translate("logIn")}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              )}
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default UserPassword;
