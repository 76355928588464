import React from 'react';

import logo from "../../assets/img/logodatalife_app_login.svg";
import logoviacon from "../../assets/img/logoviacon_app_login.svg";

const logoFull = process.env.REACT_APP_BRANDING === "viacon" ? logoviacon : logo;
const logoAlt = process.env.REACT_APP_BRANDING === "viacon" ? "Logo viacon" : "Logo Data Life";

export const MainLogo = (props) => {
  const {width, height, marginBottom, mode} = props;
  
  return (
    <img
      src={logoFull}
      alt={logoAlt}
      style={{ width, height, marginBottom }}
      mode={mode ? mode : "fit"}
    />
  );
};
